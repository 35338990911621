import React, { useState, useEffect } from 'react';
import { DateDataBox } from './DateDateBox'
import { ActionDataBox } from './ActionData'
import { SymbolDataBox } from './SymbolBox'
import { AllActionType, BONUS_SHARE,actionToTypeIdMap, getKey } from './ActionType';
import { STOCK_SPLIT } from './ActionType';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios';
import { setneedRefresh } from '../../actions/postAction'
import { selectPost } from '../../actions/postAction'
import { Col, Row, Container , Form, Button, Modal} from 'react-bootstrap'
import {useToken} from "../../auth/useToken";

export default function FormBox() {
  const selectedRow = useSelector(state => state.selectedPost)
  const dispatchreduc = useDispatch()
  const [show, setShow] = useState(false);
  const [lastSelectedActionId, setLastSelectedActionId] = useState(-1);
  const [selectingActionType, setSelectingActionType] = useState(false)
  const [showConfirmMessage, setshowConfirmMessage] = useState(false);
  const [netWorkError, setNetWorkError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [needClearing, setNeedClearing] = useState(false);
  const [actionType, setActionType] = useState("");
  const [token, setToken] = useToken();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseConfirmMessage = () => setshowConfirmMessage(false);
  const handleCloseErrorMessage = () => setNetWorkError(false);
  const handleShowConfirmMessage = () => setshowConfirmMessage(true);

  const { getValues,setValue, register, handleSubmit, watch, reset, formState: { errors } } = useForm({
    defaultValues: {
      action_status: "Pending",
      action_priority: "3",
      ann_date: "mm-dd-yyyy",
      completion_date: "",
      corp_act_type: "",
      effective_date: "",
      exchange: "",
      number_of_shares: "",
      rights_end_date: "",
      symbol: "",
    },
  });

  useEffect(() => {
    if (selectedRow) {
      setSelectingActionType(true)
      reset({
        exchange: '',
        number_of_shares: selectedRow.numberOfShares,
        number_of_shares_new: selectedRow.numberOfSharesNew,
        action_status: selectedRow.actionStatus,
        action_priority: selectedRow.actionPriority,
        ...(selectedRow.tickerData.sourceId && { exchange: selectedRow.tickerData.sourceId }),
        ...(selectedRow.tickerData.tikcerSerial && { tickerSerial: selectedRow.tickerData.tikcerSerial }),
        ...(selectedRow.dividendAmount && { dividend_amount: selectedRow.dividendAmount }),
        ...(selectedRow.cdYear && { year: selectedRow.cdYear }),
        ...(selectedRow.cdPeriod && { quarter: selectedRow.cdPeriod }),
        ...(selectedRow.abnormalCd && { abnormal_CD: selectedRow.abnormalCd }),
        ...(selectedRow.numberTreasuryStocks && { number_treasury_stocks: selectedRow.numberTreasuryStocks }),
        ...(selectedRow.issuePrice && { issue_price: selectedRow.issuePrice }),

        ...(selectedRow.annDate && { ann_date: selectedRow.annDate.split('T')[0] }),
        ...(selectedRow.rightsEndDate && { rights_end_date: selectedRow.rightsEndDate.split('T')[0] }),
        ...(selectedRow.completionDate && { completion_date: selectedRow.completionDate.split('T')[0] }),

        ...(selectedRow.effectiveDate && { effective_date: selectedRow.effectiveDate.split('T')[0] }),
        corp_act_type: getKey(selectedRow.corp_act_type)
      })
    } else {
      clearField()
    }
  }, [selectedRow])


  const watchactionType = watch("corp_act_type", STOCK_SPLIT);


  const listOption = AllActionType.map(option =>
    <option key={option} style={{ fontSize: '13px' }} value={option}>{option}</option>)



  function dispatchRefreshment(response) {
    console.log("response recieved")
    console.log(response.data)
    setLastSelectedActionId(response.data)
    setshowConfirmMessage(true)
    clearField()
    dispatchreduc(setneedRefresh(1))
  }

  function actionError(error){
    setNetWorkError(true);
    // setErrorMessage(error)
  }


  const handleAddNewAction = () => {

    handleSubmit((data) => {
      setActionType("Add")
      console.log("adding data to database")
      // data["corp_act_type"] = actionToTypeIdMap.get(data["corp_act_type"]);
      data["corp_act_type"] = actionToTypeIdMap.get(watchactionType)

      axios.post(window.SERVER_BASE_URL + '/corporateAction',data,{
        headers: { Authorization: `Bearer ${token}` }
      }).
        then((response => {

          const responsdata = response
        if (response.data > 0) {
          dispatchRefreshment(responsdata)
        } else {
          actionError(response.data)
        }

        })).catch((error)=>{
          console.log(error)
        actionError(error)
      })
    })()
  }

  const handleDeleteAction = () => {
    if (selectedRow.actionId) {
      setActionType("Delete")
      setLastSelectedActionId(selectedRow.actionId)
      handleClose()
      axios.delete(window.SERVER_BASE_URL + `/corporateAction/${selectedRow.actionId}`, {
        headers: { Authorization: `Bearer ${token}` }
      }).
        then(() => {
          setshowConfirmMessage(true)
          dispatchreduc(setneedRefresh(1))
          dispatchreduc(selectPost(null))

        }
        ).catch((error)=>{
        console.log(error)
        actionError(error)
      });
    }
  }

  const clearField = () => {
    dispatchreduc(selectPost(null))
    setSelectingActionType(false)
    setNeedClearing(!needClearing);
    reset({
      action_priority: 3,
      action_status: "Pending",
      ann_date: "",
      announcement_id: "",
      completion_date: "",
      corp_act_type: "BONUS_SHARE",
      docuemtn_id: "",
      effective_date: "",
      exchange: "",
      number_of_shares: 0,
      number_of_shares_new: 0,
      rights_end_date: "",
      symbol: "",

    })
  }

  const handleUpdateAction = () => {
    console.log("row is selectd")
    console.log(selectedRow)

    if (selectedRow.actionId) {
      console.log("action id")
      setActionType("Update")
      setLastSelectedActionId(selectedRow.actionId)
      handleSubmit((data) => {
        console.log("Updating data to database")
        data["new"] = data["corp_act_type"]
        
        data["corp_act_type"] = actionToTypeIdMap.get(watchactionType)
        data.actionId = selectedRow.actionId

        console.log("data to send")
        console.log(data)
        axios.put(window.SERVER_BASE_URL + `/corporateAction`, data,{
          headers: { Authorization: `Bearer ${token}` }
        }).
          then((res ) => {
          setshowConfirmMessage(true)
          dispatchreduc(selectPost(res.data))
          dispatchreduc(setneedRefresh(1))


        }).catch((error)=> {
          console.log(error)
          actionError(error)
        })
      })()
    }
  }


  return (

    <>
      <Container className="border m-2 p-1">

        {/*<div>{Object.keys(getValues()).map(function(key) { return <div>Key: {key}, Value: {getValues()[key]}</div>; })}}</div>*/}
        <Form >

          <Row className={"formDataArea"}>

            <Col md={3}></Col>

            <Col md={3}>
              <Form.Label>Action Type ID<span>&nbsp;&nbsp;&nbsp;{actionToTypeIdMap.get(watchactionType)} </span></Form.Label>
            </Col>

            <Col md={6}>
              <Row>
                <Col md={5} sm={5}>

              <Form.Label>Action Type </Form.Label>
                </Col>
                <Col md={7} sm={7}>

                <Form.Select id="corp_act_type" disabled={selectingActionType}  size={"sm"} {...register("corp_act_type")} >
                {/* <option disabled selected value> -- select an option -- </option> */}
                {listOption}
                )
              </Form.Select>
                </Col>

              </Row>
          </Col>
          </Row>

            <SymbolDataBox register={register} watch={watch} setValue={setValue} actionType={watchactionType} needClear={needClearing} errors={errors}></SymbolDataBox>
            <ActionDataBox register={register} setValue={setValue} actionType={watchactionType} errors={errors}></ActionDataBox>
            <DateDataBox register={register}></DateDataBox>
            {/* <IdDataBox register={register}></IdDataBox> */}

          <div style={{
            display: 'flex',
            flexDirection: 'row',
            flex: 'auto',


          }}>

            <div style={{
              flex: '80%',
              display: 'flex',

              justifyContent: "center"

            }}>

              {/* <button  type="button" onClick={handleAddNewAction} disabled={selectedRow?"true":""}>  */}
              <button  className="btn btn-secondary btn-sm m-2" type="button" onClick={(e) => {
                e.persist();
                handleAddNewAction()
              }} disabled={selectedRow ? 1 : 0}>

                Add New
              </button>

              <button  className="btn btn-secondary btn-sm m-2" disabled={!selectedRow ? 1 : 0} type="button" onClick={handleUpdateAction} >
                Update
              </button>


              <button  className="btn btn-secondary btn-sm m-2" type="button" onClick={clearField} >
                Clear All
              </button>

              <button  className="btn btn-secondary btn-sm m-2" disabled={!selectedRow ? 1 : 0} type="button"
                       onClick={handleShow}
               >
                Delete
              </button>
            </div>



            <span style={{
              fontSize: 12,
              flex: '10%',
              justifyContent: 'flex-end'

            }}>
              <a>selected action id {(selectedRow) ? selectedRow.actionId : 0}</a>
            </span>
          </div>


        </Form>
      </Container>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>Please ensure and then confirm</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            No, Cancel
          </Button>
          <Button variant="primary" onClick={handleDeleteAction}>
            yes, delete
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showConfirmMessage} onHide={handleCloseConfirmMessage}>
        <Modal.Header closeButton>
          <Modal.Title>
            Confirmation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          Confirm the {actionType} for {lastSelectedActionId }

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseConfirmMessage}>
            ok
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={netWorkError} onHide={handleCloseErrorMessage}>
        <Modal.Header closeButton>
          <Modal.Title>
            Error on Saving Data
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Getting error from Server
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseErrorMessage}>
            ok
          </Button>
        </Modal.Footer>
      </Modal>

    </>
  );
}

