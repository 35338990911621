import SearchBox from '../components/SearchBox.js';
import FormBoxNew from '../components/Form/FormBoxNew';
import React, { useState, useEffect } from 'react';
import { fetchPosts } from '../actions/postAction'
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import { PaginationTable } from '../components/TableWithPagination/PaginationTable';
import { QueryClientProvider, QueryClient } from 'react-query';
import { selectPost } from '../actions/postAction'
import { Col, Row, Container, Navbar, Nav, Button} from 'react-bootstrap'
import {searchSymbol} from '../actions/postAction'

const queryClient = new QueryClient();

function ActionPageNewWithPagination() {
    const dispatch = useDispatch()
    const navigate = useNavigate()


    const data = [];
    const [dataset, setData2] = useState([data]);
    const [select, setSelect] = useState(0);

    function onChange(newValue) {
        setSelect(newValue)
    }

    useEffect(() => {
        dispatch(fetchPosts())
    }, [])

    const logOut = () => {
        dispatch(searchSymbol("","", "" ))
        dispatch(selectPost(null))
        localStorage.removeItem('token');
        navigate("./login", { replace: true });

    }
    return (
        <>
        <Navbar bg="light" expand="lg">
            <Container>
                <Navbar.Brand href="#home">DCP Admin</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse className="justify-content-end">
                    <Navbar.Text  className="me-2"
                    >
                    </Navbar.Text>
                    <Button type={"button"} onClick={logOut} variant="outline-dark">Logout</Button>
                </Navbar.Collapse>
            </Container>
        </Navbar>

        <Container>


            <Row>
                <Col md={4}>
                    <SearchBox setDatefun={(value) => setData2(value)} />
                </Col>

                <Col md={8}>
                    <FormBoxNew data={dataset[select]} />
                </Col>
            </Row>

            <Row>
                <QueryClientProvider client={queryClient}>

                    <div  className="flex-table" >
                        <PaginationTable></PaginationTable>
                    </div>
                </QueryClientProvider>

            </Row>

        </Container>

        </>

    );
}



export default (ActionPageNewWithPagination);
