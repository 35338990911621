import axios from 'axios';
import React, {useState, useEffect, useRef} from "react";
import { Col, Row} from 'react-bootstrap'
import './SearchBox.css'
import { useToken } from '../auth/useToken';

export const SearchSuggestion = ({setSymbnolForSearching})=> {
    const [token, setToken] = useToken();
    const [display, setDisplay] = useState(false);
    const [options, setOptions] = useState([]);
    const [optionsdata, setoptionsdata] = useState([]);
    const [optionsDataFiltered, setOptionsDataFiltered] = useState([]);
    const [lastSearched, setLastSearched] = useState("");

    const [search, setSearch] = useState("");
    const [symbol, setSymbol] = useState({});
    const wrapperRef = useRef(null);
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
          const controller = new AbortController();

        if (search.length < 1) {
            setSymbnolForSearching({})
        }


        if(search.length == 2  && lastSearched!=search) {
            const params = {
                symbol_text: search,
            }
            console.log("Search" + search)
            console.log(search)
            // if (search === "") {
            //     setSymbnolForSearching({})
            // }

            setIsFetching(true)
            axios.get(window.SERVER_BASE_URL + "/searchingTicker", {
                signal: controller.signal,
                params: params,
                headers: {Authorization: `Bearer ${token}`}

            })
                .then(res => {
                    console.log("searching result")
                    console.log(res.data);
                    console.log(search);
                    setLastSearched(search);
                    if(search === res.data.searchText){



                    setoptionsdata(res.data.results)
                    setOptionsDataFiltered(res.data.results)
                    //   console.log(optionsdata)
                    //   if(optionsdata.length==0){
                    //       setSymbnolForSearching({})
                    //   }
                    }
                }).finally(
                setIsFetching(false)
            )
        } else if (search.length<2) {
            setoptionsdata([])
            setOptionsDataFiltered([])
            setLastSearched("")

        } else {
            setOptionsDataFiltered(optionsdata.filter((option,i )=>  option["tickerId"].startsWith(search) ));
        }

    }, [search, isFetching ,setIsFetching, lastSearched, setLastSearched]);

  
    useEffect(() => {
      window.addEventListener("mousedown", handleClickOutside);
      return () => {
        window.removeEventListener("mousedown", handleClickOutside);
      };
    });
  
    const handleClickOutside = event => {
      const { current: wrap } = wrapperRef;
      if (wrap && !wrap.contains(event.target)) {
        setDisplay(false);
      }
    };
  
    const updatePokeDex = poke => {
      setSearch(poke);
      setDisplay(false);
    };

    function onClickSuggestions(e){
        console.log("selected")
        setSymbol(e)
        setSearch(e.tickerId + "-" +e.longDes);
        setDisplay(false)
        setSymbnolForSearching(e)
      };

    const SuggestionsListComponent = () => {
        return optionsDataFiltered.length ? (
          <ul className="suggestions">
            {optionsDataFiltered.map((suggestion, index) => {
              let className;
              return (
                <li  key={index} onClick={() =>onClickSuggestions(suggestion)} >
                <a> {suggestion.tickerId}&nbsp;&nbsp;&nbsp;&nbsp; </a>
                  <a>{suggestion.longDes}</a>
                </li>
              );
            })}
          </ul>
        ) : (
          <div className="no-suggestions">
            <em>No suggestions</em>
          </div>
        );
      };


  
    return (
      <div ref={wrapperRef}>
          <Row>

              <Col md={5}>
                  <label>Symbol&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</label>
              </Col>

              <Col md={7}>
                  <input className='form-control mb-3'
                         id="auto"
                         onClick={() => setDisplay(!display)}
                         placeholder="Type to search"
                         value={search}
                         onChange={event => {
                             setDisplay(true)
                             setSearch(event.target.value)
                         }}
                         autoComplete="off"
                  />

                  {display && search && <SuggestionsListComponent/>}
              </Col>
          </Row>
      </div>
    );
  };
  

export default SearchSuggestion;