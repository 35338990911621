export const columns = [
    {
        Header: 'Action Id',
        accessor: 'actionId'
    },
    {
        Header: 'Symbol',
        accessor: 'tickerData.shortDesc'
    },
    {
        Header: 'Number of Shares',
        accessor: 'numberOfSharesNew'
    },
    {
        Header: 'Announcing Date',
        accessor: 'annDate'
    },
    {
        Header: 'Split Factor',
        accessor: 'splitFactor'
    }, 
    {
        Header: 'Effective Date',
        accessor: 'effectiveDate'
    },
    {
        Header: 'Action Status',
        accessor: 'actionStatus'
    }
]