import { Navigate, Route, Outlet } from 'react-router-dom';
import { useUser } from './useUser';
import ActionPageNewWithPagination from "../pages/ActionPageWithPagination"

const parseJwt = (token) => {
    try {
        return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
        return null;
    }
};

export const PrivateRoute = props => {
    const user = useUser();
    // if (!user) return <Navigate to="/login" />
    //
    // return < ActionPageNewWithPagination/>


    var isNotExpired = true;

    if(user) {
        const existingToken = localStorage.getItem('token');
        const decodedJwt = parseJwt(existingToken);
        if (decodedJwt.exp * 1000 < Date.now()) {
            isNotExpired = false;
        }
    }


    return user && isNotExpired ? <Outlet /> : <Navigate to="/login" />;

}