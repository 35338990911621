
import React, {useState, useEffect} from "react";
import axios from 'axios';
import { Col, Row, Container , Form, Button} from 'react-bootstrap'
import {useToken} from "../../auth/useToken";
import warning from "react-redux/lib/utils/warning";
import {useDispatch, useSelector} from "react-redux";
import {selectExchange} from '../../actions/postAction'
import './Form.css'
export const SymbolDataBox = ({ register, watch, setValue, needClear, errors, actionType })  =>   {
    const [token, setToken] = useToken();
    const selectedRow = useSelector(state => state.selectedPost)
    const needRefresh = useSelector(state => state.needRefresh)

    const editable = useState("disabled")
    // const [exchange, setExchange] = useState(exchangeList[0].exchange);
    const [symbolList, setSymbolList] = useState([{}]);
    const [remoteExchangeList, setRemoteExchangeList] = useState(["Select"]) ;
    const [priorityChecked, setpriorityChecked ] = useState([false, false, true]);
    const dispatch = useDispatch()

    const type = "checkbox"
    function listOption(){
        var keys = [...remoteExchangeList.keys()];
        return keys.map(
            (option, i) => <option key={option} value={option}>{option}</option>

        )}



    function listSymbolOption () {
        if(watchExchange===""){
            return  (<option value=""  disabled hidden>select exchange</option>)
        }

        return symbolList.map((option,i)=>
        <option  key={i} value={option["tickerSerial"]}>{option["tickerId"]} - {option["longDesc"]}</option>)
    }


    useEffect(()=> {
        // console.log(selectedRow.actionPriority)

        if(selectedRow) {
            switch (selectedRow.actionPriority) {
                case 1:
                    setpriorityChecked([true, false, false]);
                    break;
                case  2:
                    setpriorityChecked([false, true, false]);
                    break;
                case 3:
                    setpriorityChecked([false, false, true]);
                    break;
                default:
                    setpriorityChecked([false, false, false])
                    console.log("action priority is not in range " + selectedRow.actionPriority)
            }
        } else {
            if (actionType === "CASH_DIVIDEND" ){
                setpriorityChecked([true, false, false]);
                setValue('action_priority', 1)
            }  else {
                setpriorityChecked([false, false, true]);
                setValue('action_priority', 3)
            }
        }

    }, [actionType, needRefresh, selectedRow])


    const watchExchange = watch('exchange', "")

    function setActionPriorityRowSelected(){

        if(selectedRow) {
            switch (selectedRow.actionPriority) {
                case 1:
                    setpriorityChecked([true, false, false]);
                    break;
                case  2:
                    setpriorityChecked([false, true, false]);
                    break;
                case 3:
                    setpriorityChecked([false, false, true]);
                    break;
                default:
                    setpriorityChecked([false, false, false])
                    console.log("action priority is not in range " + selectedRow.actionPriority)
            }
        } else {
            setpriorityChecked([false, false, true])
        }
    }

    useEffect(()=>{setActionPriorityRowSelected()}, [selectedRow, needClear])


    useEffect(()=> {
        axios.get(window.SERVER_BASE_URL + "/exchangeList" , {
            headers: { Authorization: `Bearer ${token}` }
        })
        .then( res =>{
            if (res.status == 200) {
                let result;
                result = new Map(res.data.map(key => [key.sourceId, key.defaultCurrencyId]));
                setpriorityChecked([false, false, true]);

                setRemoteExchangeList(result)
            }
        })
    }, [])

    useEffect(()=>{
        if(watchExchange!==""){
        axios.get(window.SERVER_BASE_URL + "/tikcersPerExchange" , {
            params : { sourceId: watchExchange},
            headers: { Authorization: `Bearer ${token}` }
        })
            .then(res=>{
                dispatch(selectExchange(remoteExchangeList.get(watchExchange)))
                setSymbolList(res.data)
                if(selectedRow){
                    console.log(selectedRow.tickerData.tickerSerial)
                    setValue('tickerSerial', selectedRow.tickerData.tickerSerial)
                }
            })

    // const exchange = remoteExchangeList.filter(exchange=> exchange.exchangeId===watchExchange)[0]
    //
    //     if(exchange !== undefined) {
    //         setSymbolList(exchange.tickerDataList)
    //         dispatch(selectExchange(exchange))
    //     }
    //

    }
}, [watchExchange])
    


    return (

            <Row>
                <Col md={6}><Row>
               <Form.Group className="mb-1" controlId="formGroupEmail">
               <Row className='mt-2'>
                   <Col md={5} sm={5}>
               <Form.Label>Exchange</Form.Label>
                   </Col>

                   <Col md={7} sm={7}>

                   <Form.Select size="sm" style={{ width: "100%" }}  {...register('exchange',
                       { required: true , message: "Please select a exchange"})}
                                isInvalid={errors.exchange}>
                     <option value=""  disabled hidden>Choose one</option>

            {listOption()}
            </Form.Select>
                       <Form.Control.Feedback type={"invalid"}> {errors.exchange ? (<>
                           <a>
                               {errors.exchange.message}
                           </a>
                       </>) : null}</Form.Control.Feedback>
                   </Col>

           </Row>
               </Form.Group>

                    <Form.Group className="mb-1" controlId="formGroupEmail">
                    <Row  className='mt-2'>
                        <Col md={5} sm={4}>

                    <Form.Label>Symbol</Form.Label>
                        </Col>

                        <Col md={7} sm={8}>

                        <Form.Select  size="sm" defaultValue={""} style={{ width: "100%" }}
                                      {...register('tickerSerial', { required: true , message: "Please select a Symbol"})}
                                      isInvalid={errors.tickerSerial}
                        >
                        (<option value=""  disabled hidden>select symbol</option>)

                        {listSymbolOption()}

                    </Form.Select></Col>
                        <Form.Control.Feedback type={"invalid"}> {errors.tickerSerial ? (<>
                            <a>
                                {errors.tickerSerial.message}
                            </a>
                        </>) : null}</Form.Control.Feedback>

                    </Row>
                    </Form.Group>



                    </Row>
                </Col>

                <Col md={6}>
                    <Form.Group>
                    <Row className='mb-2 mt-2'>
                        <Col md={6}>

                    <Form.Label>Action Priority</Form.Label>
                        </Col>

                        <Col md={6}>
                            <div key={`inline-checkbox`} >
                                <Form.Check
                                    inline
                                    label="1"
                                    name="group1"
                                    type="checkbox"
                                    value={1}
                                    checked={priorityChecked[0]}
                                    // disabled={priorityChecked}
                                    onChange={()=>{}}
                                    onClick={
                                        ()=> {
                                            // setValue('actionPriority', 3)
                                            if(priorityChecked[0]){
                                                setpriorityChecked([false, false, false])
                                                setValue('action_priority', 0)


                                            } else {
                                                setpriorityChecked([true, false, false])
                                                setValue('action_priority', 1)

                                            }
                                        }}
                                    // onClick={setpriorityChecked(true)}
                                />
                                <Form.Check
                                    inline
                                    label="2"
                                    name="group1"
                                    type="checkbox"
                                    value={2}
                                    checked={priorityChecked[1]}
                                    // disabled={priorityChecked}
                                    onChange={()=>{}}
                                    onClick={
                                        ()=> {
                                            // setValue('actionPriority', 3)
                                            if(priorityChecked[1]){
                                                setpriorityChecked([false, false, false])
                                                setValue('action_priority', 0)


                                            } else {
                                                setpriorityChecked([false, true, false])
                                                setValue('action_priority', 2)

                                            }
                                        }}
                                    // onClick={setpriorityChecked(true)}

                                />
                                <Form.Check
                                    inline
                                    label="3"
                                    type="checkbox"
                                    value={3}
                                    // disabled={priorityChecked}
                                    checked={priorityChecked[2]}
                                    onChange={()=>{}}
                                    onClick={
                                    ()=> {
                                        if(priorityChecked[2]){
                                            setpriorityChecked([false, false, false])
                                            setValue('action_priority', 0)


                                        } else {
                                            setpriorityChecked([false, false, true])
                                            setValue('action_priority', 3)
                                        }
                                    }}

                                />
                            </div>

                        </Col>



                    </Row>
                    </Form.Group>
                </Col>
            </Row>



    )}