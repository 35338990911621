import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux';
import store from './store'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import ActionPageNewWithPagination from "./pages/ActionPageWithPagination"
import LoginPage from "./pages/LoginPage";
import 'bootstrap/dist/css/bootstrap.min.css';
import {PrivateRoute} from './auth/PrivateRoute';



ReactDOM.render(
  <Provider store={store}>
      <BrowserRouter>
      <Routes>
          <Route exact path='/' element={<PrivateRoute/>}>
              <Route exact path='/' element={<ActionPageNewWithPagination/>}/>
          </Route>
          <Route exact path='/login' element={<LoginPage/>}/>
      </Routes>
  </BrowserRouter>,
    </Provider>,

  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
