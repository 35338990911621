
import {combineReducers} from 'redux';
import AllPostReducer from './reducer-Allpost';
import SelectedPostReducer from './reducer-selectedPost'
import NeedRefreshReducer from './reducer-needUpdate'
import searchdata from './reducer-searchdata'
import selectExchange from './reducer-selectExchange'

const rootReducer=combineReducers({
    allPosts:AllPostReducer,
    selectedPost:SelectedPostReducer,
    needRefresh: NeedRefreshReducer,
    searchData:  searchdata,
    selectExchange: selectExchange
})

export default rootReducer;