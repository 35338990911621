import {FETCH_ALL_DATA, SELECT_ROW, NEED_REFRESH, SEARCH_DATA, SELECT_EXCHANGE } from './types'


export const fetchPosts=()=>dispatch=>{
    // axios.get(configData.SERVER_BASE_URL + "/all" , {
    //     headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    // })
    // .then(res=>{
    //   dispatch({
    //     type:FETCH_ALL_DATA,
    //     payload:res.data
    //   })
    // })
    dispatch({
        type:FETCH_ALL_DATA,
        payload:{}
    })
}

// export const searchSymbol=(symbol, startDate, endDate)=>dispatch=>{
// console.log(symbol)
// const params= {
// symbolText: symbol,
//   ...(startDate && {startDate: startDate}),
//   ...(endDate && {endDate: endDate})
// }
//   console.log(params)
//     axios.get("http://localhost:8080/search", {params: params})
//     .then(res=>{
//       console.log(res);
//       dispatch({
//         type:FETCH_ALL_DATA,
//         payload:res.data
//       })
//     })
// }

export const searchSymbol=(symbol, startDate, endDate)=>dispatch=>{
  const params= {
    ...(symbol.tickerSerial &&  {tickerSerial  : symbol.tickerSerial}),
    ...(startDate && {startDate: startDate}),
    ...(endDate && {endDate: endDate})
  }
  dispatch({ type:SEARCH_DATA,
          payload:params
        
      })
  }

export const selectExchange=(exchange)=>dispatch=>{
    dispatch({
        type: SELECT_EXCHANGE,
        payload:exchange

    })
}

export const addNewAction=(data)=>dispatch=>{
  console.log(data);
}


export const selectPostDummy=()=>{
    return{
      type:FETCH_ALL_DATA,
      payload:"post"
    }
  };

export const selectPost=(post)=>{
  return{
    type:SELECT_ROW,
    payload:post
  }
};

export const setneedRefresh=(post)=>{
  return{
    type:NEED_REFRESH,
    payload:post
  }
};