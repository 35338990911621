import {SELECT_EXCHANGE} from '../actions/types'

export default function (state={}, action){
    switch (action.type){
        case SELECT_EXCHANGE:
            return action.payload;
            break;
    }
    return state

}