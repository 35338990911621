
import React, { useState } from "react";
import {LAYOUT1, LAYOUT2, LAYOUT3, LAYOUT4, LAYOUT6} from "./ActionType";
import { currency } from "./ActionType";
import { Col, Row , Form} from 'react-bootstrap'

import './Form.css'
import {useSelector} from "react-redux";
export const ActionDataBox = ({ actionType, register, errors, setValue }) => {
    const [symbol, setSymbol] = useState(currency[0].currency);

    const listOption = currency.map(option =>
        <option value={option.currency}>{option.currency}</option>)

    const selectExchange = useSelector(state => state.selectExchange)

    function handleSelect(event) {
        setSymbol(event.target.value)
    }

    const editable = useState("disabled")
    if (LAYOUT1.includes(actionType)) {
        return (
            <>
            <Row>
                <Col md={6} className='mb-2 mt-2'>
                    <Row className='mt-2'>

                        <Form.Group className="mb-1"  controlId="numberOfShares" >
                            <Row >
                  <Col md={5} sm={5}>
                        <Form.Label>Number of Shares</Form.Label>
                        </Col>

                        <Col md={7} sm={7}>
                        <Form.Control
                            style={{ width: "100%" }}
                            placeholder={"0"}
                            size="sm" type="number"
                            {...register('number_of_shares_new',
                                { maxLength: { value: 20, message: "20 numbers max" }})}
                            isInvalid={errors.number_of_shares_new}
                        />
                            <Form.Control.Feedback type={"invalid"}> {errors.number_of_shares_new ? (<>
                                <a>
                                    {errors.number_of_shares_new.message}
                                </a>
                            </>) : null}</Form.Control.Feedback>

                        </Col>

                            </Row>
                        </Form.Group>
                    </Row>
                </Col>



                <Col md={6} className='mb-2 mt-2'>
                        <Form.Group className="mb-1" controlId="formGroupEmail">
                            <Row  className='mt-2'>

                        <Col md={5} sm={5}>
                    <Form.Label>Action Status: </Form.Label>
                        </Col>
                        <Col md={7} sm={7}>
                        <Form.Select  size="sm"  name="action_status"
                                      {...register('action_status', { required: true, message: "need to one"})}>
                        <option value="Pending">Pending</option>
                        <option value="Executed">Executed</option>
                        <option value="Suspend">Suspended</option>
                    </Form.Select>
                        </Col>
                            </Row>
                        </Form.Group>
                </Col>
            </Row>

            <Row>
            <Col md={6} className='mb-2 mt-0'>
                    <Row className='mt-0'>

                        <Form.Group className="mb-1"  controlId="numberOfSharesOld" >
                            <Row >
                        <Col md={5} sm={5}>
                        <Form.Label>Number of Shares Old</Form.Label>
                        </Col>

                        <Col md={7} sm={7}>
                        <Form.Control
                            style={{ width: "100%" }}
                            placeholder={"0"}
                            size="sm" type="number"
                            {...register('number_of_shares',
                                { maxLength: { value: 20, message: "20 numbers max" }})}
                            isInvalid={errors.number_of_shares}/>
                            <Form.Control.Feedback type={"invalid"}> {errors.number_of_shares ? (<>
                                <a>
                                    {errors.number_of_shares.message}
                                </a>
                            </>) : null}</Form.Control.Feedback>

                        </Col>

                            </Row>
                        </Form.Group>
                    </Row>
                </Col>
            </Row>



            </>
        )
    } else if (LAYOUT2.includes(actionType)) {


        if(selectExchange){
            console.log(selectExchange)
            setValue("currency_id", selectExchange)
        }

        return(
            <>

                <Row>
                    <Col md={6}  className='mb-2 mt-2'>
                        <Row className='mt-2'>
                            <Form.Group className="mb-1" controlId="dividendAmount">
                                <Row>
                                    <Col md={5} sm={5}>
                                         <Form.Label>Dividend Amount</Form.Label>
                                    </Col>

                                    <Col md={4} sm={4}>
                                        <Form.Control placeholder={"0.0000"} type="number"  size="sm"  {...register('dividend_amount')}/>
                                    </Col>

                                    <Col md={3} sm={3}>
                                    <Form.Select  id="cars"  size="sm" {...register('currency_id', { required: true })}>
                                            {listOption}
                                             </Form.Select>

                                    </Col>

                                </Row>
                            </Form.Group>
                        </Row>
                 </Col>

                    <Col md={6} className='mb-2 mt-2'>
                        <Form.Group className="mb-1" controlId="formGroupEmail">
                            <Row  className='mt-2'>

                                <Col md={5} sm={5}>
                                    <Form.Label>Action Status: </Form.Label>
                                </Col>
                                <Col md={7} sm={7}>
                                    <Form.Select  size="sm"  name="action_status"  {...register('action_status', { required: true })}>
                                        <option value="Pending">Pending</option>
                                        <option value="Executed">Executed</option>
                                        <option value="Suspend">Suspended</option>
                                    </Form.Select>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>
                </Row>




                <Row>
                    <Col md={6} className='mb-2'>
                            <Row>
                                 <Form.Group className="mb-1"  controlId="year" >
                                     <Row>
                                        <Col md={5} sm={5}>
                                             <Form.Label>Year </Form.Label>
                                        </Col>


                                            <Col md={7} sm={7}>
                                                <Form.Control placeholder={"0000"} style={{ width: "50%" }} size={"sm"} type="number" {...register('year', {maxLength: { value: 4, message: "4 numbers max" }})}
                                                />

                                                {errors.year ? (<>
                                                 <a style={{ color: "red", textAlign: "center" }}>
                                                        {errors.year.message}
                                                    </a>
                                                </>) : null}
                                            </Col>
                                    </Row>
                                 </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group className="mb-1" controlId="formGroupEmail">
                                    <Row  className='mt-0.5'>
                                        <Col md={5} sm={5}>
                                            <Form.Label>Quarter</Form.Label>
                                        </Col>
                                        <Col md={7} sm={7}>
                                        <Form.Select name="quarter" size={"sm"} style={{ width: "50%" }} {...register('quarter', { })}
                                        >
                                            <option value="" selected disabled hidden>Choose one</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3 </option>
                                            <option value="4">4 </option>
                                        </Form.Select>
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Row>
                    </Col>



                    <Col md={6} className='mb-2 mt-2'>
                        <Row className='mt-2'>
                            <Form.Group className="mb-1"  controlId="abnormalCD">
                                <Row>
                                    <Col md={5} sm={5}>
                                        <Form.Label>Abnormal CD </Form.Label>
                                    </Col>

                                    <Col md={7} sm={7}>
                                        <Form.Check type="checkbox"   {...register('abnormal_CD')}
                                            />
                                    </Col>
                                </Row>
                        </Form.Group>
                        </Row>
                    </Col>

                </Row>

            </>)
    } else if(LAYOUT6.includes(actionType)) {
        return (
            <>
                <Row>
                    <Col md={6} className='mb-2 mt-2'>
                        <Row className='mt-2'>

                            <Form.Group className="mb-1"  controlId="numberOfShares" >
                                <Row >
                                    <Col md={5} sm={5}>
                                        <Form.Label>Number of Shares</Form.Label>
                                    </Col>

                                    <Col md={7} sm={7}>
                                        <Form.Control
                                            style={{ width: "100%" }}
                                            placeholder={"0"}
                                            size="sm" type="number"
                                            {...register('number_of_shares_new',
                                                { maxLength: { value: 20, message: "20 numbers max" }})}
                                            isInvalid={errors.number_of_shares_new}
                                        />
                                        <Form.Control.Feedback type={"invalid"}> {errors.number_of_shares_new ? (<>
                                            <a>
                                                {errors.number_of_shares_new.message}
                                            </a>
                                        </>) : null}</Form.Control.Feedback>

                                    </Col>

                                </Row>
                            </Form.Group>
                        </Row>
                    </Col>



                    <Col md={6} className='mb-2 mt-2'>
                        <Form.Group className="mb-1" controlId="formGroupEmail">
                            <Row  className='mt-2'>

                                <Col md={5} sm={5}>
                                    <Form.Label>Action Status: </Form.Label>
                                </Col>
                                <Col md={7} sm={7}>
                                    <Form.Select  size="sm"  name="action_status"  {...register('action_status', { required: true })}>
                                        <option value="Pending">Pending</option>
                                        <option value="Executed">Executed</option>
                                        <option value="Suspend">Suspended</option>
                                    </Form.Select>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col md={6} className='mb-2 mt-0'>
                        <Row className='mt-0'>

                            <Form.Group className="mb-1"  controlId="numberOfSharesOld" >
                                <Row >
                                    <Col md={5} sm={5}>
                                        <Form.Label>Number of Shares Old</Form.Label>
                                    </Col>

                                    <Col md={7} sm={7}>
                                        <Form.Control
                                            style={{ width: "100%" }}
                                            placeholder={"0"}
                                            size="sm" type="number"
                                            {...register('number_of_shares',
                                                { maxLength: { value: 20, message: "20 numbers max" }})}
                                            isInvalid={errors.number_of_shares}/>
                                        <Form.Control.Feedback type={"invalid"}> {errors.number_of_shares ? (<>
                                            <a>
                                                {errors.number_of_shares.message}
                                            </a>
                                        </>) : null}</Form.Control.Feedback>

                                    </Col>

                                </Row>
                            </Form.Group>
                        </Row>
                    </Col>

                    <Col md={6} className='mb-2 mt-2'>
                        <Form.Group className="mb-1" controlId="formGroupEmail">
                            <Row>

                                <Col md={5} sm={5}>
                                    <Form.Label>Issue Price </Form.Label>
                                </Col>
                                <Col md={7} sm={7}>
                                    <Form.Control placeholder={"0.0000"} style={{ width: "100%" }} size="sm" type="number" {...register('issue_price')}/>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>
                </Row>


            </>
        )


    }

    else if (LAYOUT3.includes(actionType)) {
        return (  <>
                <Row>
                    <Col md={6} className='mt-2'>
                        <Row className='mt-2'>

                            <Form.Group className="mb-1"  controlId="numberOfShares" >
                                <Row >
                                    <Col md={5} sm={5}>
                                        <Form.Label>Number of Treasury Stocks</Form.Label>
                                    </Col>

                                    <Col md={7} sm={7}>
                                        <Form.Control placeholder={"0"} style={{ width: "100%" }} size="sm" type="number" {...register('number_treasury_stocks', {})}/>
                                    </Col>
                                    {/*{errors.number_of_shares ? (<>*/}
                                    {/*    <a>*/}
                                    {/*        {errors.number_of_shares.message}*/}
                                    {/*    </a>*/}
                                    {/*</>) : null}*/}
                                </Row>
                            </Form.Group>
                        </Row>
                    </Col>



                    <Col md={6} className='mb-2 mt-2'>
                        <Form.Group className="mb-1" controlId="formGroupEmail">
                            <Row  className='mt-2'>

                                <Col md={5} sm={5}>
                                    <Form.Label>Action Status: </Form.Label>
                                </Col>
                                <Col md={7} sm={7}>
                                    <Form.Select  size="sm"  name="action_status"  {...register('action_status', { required: true })}>
                                        <option value="Pending">Pending</option>
                                        <option value="Executed">Executed</option>
                                        <option value="Suspend">Suspended</option>
                                    </Form.Select>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>
                </Row>


            </>
        )

    } else if (LAYOUT4.includes(actionType)) {
        return (<>
            <div className="input-box">
                <label>Per Value &nbsp;&nbsp;: </label>
                <input type="number"   {...register('per_value')}
                />
            </div>

            <div className="input-box">

                <label>Action Status &nbsp;&nbsp;: </label>
                <select name="action_status"  {...register('action_status', { required: true })}>
                    <option value="Pending">Pending</option>
                    <option value="Executed">Executed</option>
                    <option value="Suspend">Suspended</option>

                </select>
            </div>
        </>
        )
    } else {
        return (
            <>
                <div className="input-box">
                    <label>Old Paid &nbsp;&nbsp;: </label>
                    <input type="number"   {...register('old_paid', { required: true })}
                    />
                </div>

                <div className="input-box">
                    <label>New Paid &nbsp;&nbsp;: </label>
                    <input type="number"   {...register('new_paid', { required: true })}
                    />
                </div>

                <div className="input-box">
                    <label>Cash Paid &nbsp;&nbsp;: </label>
                    <input type="number"   {...register('cash_paid', { required: true })}
                    />
                </div>

                <div className="input-box">

                    <label>Action Status &nbsp;&nbsp;: </label>
                    <select name="action_status"  {...register('action_status', { required: true })}>
                        <option value="Pending">Pending</option>
                        <option value="Executed">Executed</option>
                        <option value="Suspend">Suspended</option>

                    </select>
                </div>
            </>
        )
    }

}