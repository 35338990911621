import React, {useEffect} from 'react';
import styled from 'styled-components';
import { useTable, usePagination } from 'react-table';
import { useQuery } from 'react-query';
import { FetchActionPageData } from './fetchData';
import {columns} from './columns'
import {ErrorBoundary} from './ErrorBoundary'
import { useDispatch, useSelector } from 'react-redux'
import {selectPost} from '../../actions/postAction'
import {setneedRefresh} from '../../actions/postAction'
import './table.css'


const initialState = {
  queryPageIndex: 0,
  queryPageSize: 20,
  totalCount: null,
  symbol: null,
  startDate: null,
  endDate: null
};

const PAGE_CHANGED = 'PAGE_CHANGED';
const PAGE_SIZE_CHANGED = 'PAGE_SIZE_CHANGED';
const TOTAL_COUNT_CHANGED = 'TOTAL_COUNT_CHANGED';

const reducer = (state, { type, payload }) => {
  switch (type) {
    case PAGE_CHANGED:
      return {
        ...state,
        queryPageIndex: payload,
      };
    case PAGE_SIZE_CHANGED:
      return {
        ...state,
        queryPageSize: payload,
      };
    case TOTAL_COUNT_CHANGED:
      return {
        ...state,
        totalCount: payload,
      };
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};

export const  PaginationTable = ()=> {
  const dispatchreduc = useDispatch()
  const doesneedRefresh = useSelector(state => state.needRefresh)
  const searchData = useSelector(state => state.searchData)
  const [{ queryPageIndex, queryPageSize, totalCount }, dispatch] =
    React.useReducer(reducer, initialState);

  const { isLoading, error, data, isSuccess, refetch } = useQuery(
    [searchData, queryPageIndex, queryPageSize],
    () => FetchActionPageData(queryPageIndex, queryPageSize, searchData),
    {
      keepPreviousData: false,
      staleTime: Infinity,
    }
  );

  useEffect(()=>  {
    if(doesneedRefresh===1){
      refetch()
      console.log("refreshed")

      dispatchreduc(setneedRefresh(0))

    }
  }, [doesneedRefresh, refetch, dispatchreduc])



  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: isSuccess ? (data.content) : [],
      initialState: {
        pageIndex: queryPageIndex,
        pageSize: queryPageSize,
      },
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: isSuccess ? Math.ceil(totalCount / queryPageSize) : null,
    },
    usePagination
  );

  React.useEffect(() => {
    dispatch({ type: PAGE_CHANGED, payload: pageIndex });
  }, [pageIndex]);

  React.useEffect(() => {
    dispatch({ type: PAGE_SIZE_CHANGED, payload: pageSize });
    gotoPage(0);
  }, [pageSize, gotoPage]);

  React.useEffect(() => {
    if (data?.totalElements || data?.totalElements===0 ) {
      dispatch({
        type: TOTAL_COUNT_CHANGED,
        payload: data.totalElements,
      });
    }
  }, [data?.totalElements]);

  if (error) {
    return <p>Error</p>;
  }

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <ErrorBoundary>

    <>
        <table {...getTableProps()}>
            <thead style={{fontSize: '13.5px'}}>
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                        ))}

                    </tr>
                ))}

            </thead >
            <tbody {...getTableBodyProps()} style={{fontSize: '13.5px'}}>
                {page.map((row, i) => {
                    prepareRow(row)
                    return (
                        <tr key={i} {...row.getRowProps()} onClick={()=> dispatchreduc(selectPost(row.original))}>
                            {row.cells.map((cell, j) =>{
                                return <td key={j} {...cell.getCellProps}>{cell.render('Cell')}</td>
                            })}
                        </tr>
                    )
                })}
                <tr>
                    <td>

                    </td>
                </tr>
            </tbody>
        </table>
          <div className="pagination">
            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              {'<<'}
            </button>{' '}
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
              {'<'}
            </button>{' '}
            <button onClick={() => nextPage()} disabled={!canNextPage}>
              {'>'}
            </button>{' '}
            <button
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {'>>'}
            </button>{' '}
            <span>
              Page{' '}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{' '}
            </span>
            <span>
              | Go to page:{' '}
              <input
                type="number"
                value={pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
                style={{ width: '100px' }}
              />
            </span>{' '}
            <select
                style={{	width: '10%'
                }}
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
   </>
   </ErrorBoundary>
      )
  
}

 
