import React, { useState, useEffect } from 'react';
import { Col, Row, Form} from 'react-bootstrap'
import './LoginPage.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useToken } from '../auth/useToken';

function LoginPage() {
    const [token, setToken] = useToken();

    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [showPassword, setShowPassword] = useState(false)

    const [emailValue, setEmailValue] = useState('');
    const [passwordValue, setPasswordValue] = useState('');

    const navigate = useNavigate();
    const onLogInClicked = async () => {
        console.log("login clicked")
        console.log(
        {
            username: emailValue,
                password: passwordValue,

        })

        let response=null;
        try {
             response = await axios.post(window.SERVER_BASE_URL + '/authenticate', {
                username: emailValue,
                password: passwordValue,
            })
        } catch (err){
            setShowErrorMessage(true)
        }


        if(response.status===403){
            setShowErrorMessage(true)
        }if(response.status===200) {
            const {token} = response.data;
            setToken(token);
            navigate("/", {replace: true});
        }
    }

    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                console.log("Enter key was pressed. Run your function.");
                onLogInClicked();
                event.preventDefault();

            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, [emailValue, passwordValue, onLogInClicked]);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <>
        <div className="d-flex justify-content-center align-items-center mt-5">
            <div className="card">
                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <h3 className="row justify-content-center mt-3">Login</h3>

                    <div className="tab-pane fade show active" id="pills-home" role="tabpanel"
                         aria-labelledby="pills-home-tab">
                        <div className="px-4 pt-5">
                            <Form style={{
                                padding:"10px",
                                height: "200px"
                            }

                            }>
                                <Form.Control  style={{
                                    marginBottom: "12px",
                                    borderRadius: "3px"
                                }} value={emailValue}
                                               isInvalid={showErrorMessage}
                                               onChange={e => {
                                                   setShowErrorMessage(false)
                                                   setEmailValue(e.target.value)}}
                                               type="text" placeholder="User Name"></Form.Control>
                                <Form.Control style={{
                                    marginBottom: "12px",
                                    borderRadius: "3px"
                                }} placeholder="Password"
                                              type={showPassword ? "text" : "password"}
                                              value={passwordValue}
                                              onChange={e => {setPasswordValue(e.target.value)
                                              setShowErrorMessage(false)
                                              }}
                                              isInvalid={showErrorMessage}
                                ></Form.Control>


                                <Row>
                                <div className="custom-control custom-checkbox">
                                    <Row>
                                    <Col md={1}>
                                    <input  onClick={handleClickShowPassword} type="checkbox" className="custom-control-input" id="defaultUnchecked"/>
                                    </Col>
                                    <Col md={11}>
                                        <label className="custom-control-label" htmlFor="defaultUnchecked">Show Password</label>
                                    </Col>
                                    </Row>

                                </div>
                                </Row>

                                <Form.Control.Feedback type={"invalid"}>Invalid Credentials</Form.Control.Feedback>

                                <button type={"button"} onClick={onLogInClicked}
                                         style={{marginTop: "20px"}} className="btn btn-dark btn-block">Login</button>
                            </Form>
                            {/*<input type="text" name="" className="form-control" placeholder="Email or Phone">*/}
                            {/*    <input type="text" name="" className="form-control" placeholder="Password">*/}
                            {/*<button className="btn btn-dark btn-block">Login</button>*/}
                                </div>
                    </div>
                </ul>

            </div>

        </div>


        </>
    );

}


export default (LoginPage);
