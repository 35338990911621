import {SELECT_ROW} from '../actions/types'

export default function (state=null,action){
    switch(action.type){
        case SELECT_ROW:
            return action.payload;
            break;
        case "TEST":
            return action.payload;
            break;

    }
    return state
}