
import { useToken } from '../../auth/useToken';
import axios from 'axios';

export const FetchActionPageData = async (page, pageSize, searchdata) => {

  const offset = page * pageSize;
  try {
     const Params = {...searchdata,
      sort : "effectiveDate,desc",
      page : page ,
      size : pageSize
      }

//     axios.get("http://localhost:8080/search", {params: params})
//     .then(res=>{
//       console.log(res);
//       dispatch({
//         type:FETCH_ALL_DATA,
//         payload:res.data
//       })
//     })
//     const response = await  fetch(
//       configData.SERVER_BASE_URL + "/searching_pageablev2?" + new URLSearchParams({...Params})
//     );

    const response = await axios.get(window.SERVER_BASE_URL + "/searching_pageablev2?" + new URLSearchParams({...Params}),
 {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    });

    const data = await response.data;
    return data;
  } catch (e) {
    throw new Error(`API error:${e?.message}`);
  }
};
