
import React, {useState} from "react";
import { Col, Row, Container , Form, Button} from 'react-bootstrap'

export const DateDataBox = ({register})  =>   {
    const editable = useState("disabled")
    

    return   (

        <>
            <Row>
                <Col md={6} className='mb-2'>

                <Row className='mb-2'>
                        <Form.Group controlId="formAnnEmail">
                            <Row>
                    <Col md={5} sm={5}>
                    <Form.Label>Announcing Date </Form.Label>
                    </Col>

                    <Col md={7} sm={7}>
                    <Form.Control size="sm" type="date"  {...register('ann_date', { required: false})}/>
                    </Col>
                            </Row>
                        </Form.Group>
                    </Row>
                </Col>




                <Col md={6} className='mb-2'>
                    <Form.Group controlId="formAnnEmail">
                        <Row>
                            <Col md={5} sm={5}>
                            <Form.Label>Rights End Date</Form.Label>
                            </Col>
                            <Col md={7} sm={7}>

                            <Form.Control size="sm" type="date"   {...register('rights_end_date', { required: false})}/>
                            </Col>

                        </Row>
                    </Form.Group>
                </Col>


            </Row>


            <Row>
                <Col md={6} className='mb-2'>

                    <Row>
                        <Form.Group controlId="formAnnEmail">
                            <Row>
                                <Col md={5} sm={5}>
                                    <Form.Label>Effective Date</Form.Label>
                                </Col>

                                <Col md={7} sm={7}>
                                    <Form.Control type="date"  size="sm" {...register('effective_date', { required: false})}/>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Row>
                </Col>




                <Col md={6} className='mb-2'>
                    <Form.Group controlId="formAnnEmail">
                        <Row>
                            <Col md={5} sm={5}>
                                <Form.Label>Completion Date</Form.Label>
                            </Col>
                            <Col md={7} sm={7}>

                                <Form.Control type="date" size="sm"  {...register('completion_date', { required: false})}/>
                            </Col>

                        </Row>
                    </Form.Group>
                </Col>
            </Row>

            </>
    )

}