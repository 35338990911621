// import './SearchBox.css';
import React, {useState} from "react";
import axios from 'axios';
import {searchSymbol, selectPost} from '../actions/postAction'
import {useDispatch } from 'react-redux'
import {SearchSuggestion} from './SearchSuggestion'
import { Col, Row, Container , Form, Button} from 'react-bootstrap'


function SearchBox(props) {

  const [symbol, setSymbol] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const dispatch = useDispatch()

  function urlConstructio (symbol){
    return (symbol);
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(selectPost(null))
    dispatch(searchSymbol(symbol,startDate, endDate ))

  }
    return (
        <Container className="border mt-2">

            <Form onSubmit={handleSubmit}>
                <h4 className='mb-2 mt-2'>Split Search</h4>

                <SearchSuggestion setSymbnolForSearching={setSymbol}></SearchSuggestion>

                <Row>
                    <Col md={5} sm={5}>
                        <label>Start Date&nbsp;:</label>
                    </Col>

                    <Col md={7} sm={7}>
                        <input className='form-control mb-3' type="date" id="start" name="start-date"
                               value={startDate}
                               onChange={(e) => setStartDate(e.target.value)}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col md={5} sm={5}>
                        <label>End Date&nbsp;:</label>
                    </Col>
                    <Col md={7} sm={7}>
                        <input className='form-control mb-3' type="date" id="end" name="end-date"
                               value={endDate}
                               onChange={(e) => setEndDate(e.target.value)}/>
                    </Col>
                </Row>
                <div className='text-center'><Button  type="submit" className="btn btn-secondary btn-sm mb-2">Search</Button></div>
            </Form>
        </Container>
    );
  }
  
  export default SearchBox;