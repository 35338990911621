import { experimental_sx } from "@mui/material";
import currencyjson  from "./currency.json"
export const STOCK_SPLIT = "STOCK_SPLIT";
export const BONUS_SHARE = "BONUS_SHARE";
const CASH_DIVIDEND = "CASH_DIVIDEND";
const RIGHT = "RIGHT";
const CONSOLIDATION = "CONSOLIDATION";
const SHARE_SWITCH = "SHARE_SWITCH";
const THREASURY_STOCK_CANCEL = "THREASURY_STOCK_CANCEL";
const BONDS_TO_SHARE = "BONDS_TO_SHARE";
const PP_PATNER = "PP_PATNER";
const DISTRIBUTED_TRASURY_STOCK= "DISTRIBUTED_TRASURY_STOCK"
const EMPLOYEE_STOCK_OPTION = "EMPLOYEE_STOCK_OPTION";
const CAPITAL_INCREASE_PAR_VALUE = "CAPITAL_INCREASE–PAR_VALUE";
const REDUCE_SHARE= "REDUCE SHARE";
const PAR_VALUE_REDUCTION = "PAR_VALUE_REDUCTION" ; 
const PATIAL_CALL = "PATIAL_CALL";

export const  actionTypeField = "corp_act_type";

export const actionToTypeIdMap = new Map().set(STOCK_SPLIT,  5 ).set(BONUS_SHARE, 9).set(CASH_DIVIDEND, 7).set(RIGHT, 8).set(CONSOLIDATION, 6)
.set(SHARE_SWITCH, 11).set(THREASURY_STOCK_CANCEL, 17).set(BONDS_TO_SHARE, 10).set(PP_PATNER, 12).set(DISTRIBUTED_TRASURY_STOCK, 18)
.set(EMPLOYEE_STOCK_OPTION, 13)
.set(CAPITAL_INCREASE_PAR_VALUE, 14)
.set(REDUCE_SHARE, 15)
.set(PAR_VALUE_REDUCTION,16)
.set(PATIAL_CALL,36);

export function getKey(val) {
    return [...actionToTypeIdMap].find(([key, value]) => val === value)[0];
  }

export const LAYOUT1 = [STOCK_SPLIT, BONUS_SHARE,CONSOLIDATION, SHARE_SWITCH, THREASURY_STOCK_CANCEL, BONDS_TO_SHARE,PP_PATNER,EMPLOYEE_STOCK_OPTION,REDUCE_SHARE];
export const LAYOUT2 = [CASH_DIVIDEND];
export const LAYOUT3 = [DISTRIBUTED_TRASURY_STOCK];
export const LAYOUT4 = [CAPITAL_INCREASE_PAR_VALUE, PAR_VALUE_REDUCTION];
export const LAYOUT5 = [PATIAL_CALL]
export const LAYOUT6 = [RIGHT]

export const actionTypeJson = [
    {
       "actionName":"STOCK_SPLIT",
       "actionId":5
    },
    {
       "actionName":"BONUS_SHARE",
       "actionId":9
    },
    {
       "actionName":"CASH_DIVIDEND",
       "actionId":7
    },
    {
       "actionName":"RIGHT",
       "actionId":8
    },
    {
       "actionName":"CONSOLIDATION",
       "actionId":6
    },
    {
       "actionName":"SHARE_SWITCH",
       "actionId":11
    },
    {
       "actionName":"THREASURY_STOCK_CANCEL",
       "actionId":17
    },
    {
       "actionName":"BONDS_TO_SHARE",
       "actionId":10
    },
    {
       "actionName":"PP_PATNER",
       "actionId":12
    },
    {
        "actionName":"DISTRIBUTED_TRASURY_STOCK",
        "actionId":18
     },
     {
        "actionName":"EMPLOYEE_STOCK_OPTION",
        "actionId": 13
     },
     {
        "actionName":"CAPITAL_INCREASE_PAR_VALUE",
        "actionId": 14
     },
     {
        "actionName":"REDUCE_SHARE",
        "actionId": 15
     },
     {
        "actionName":"PAR_VALUE_REDUCTION",
        "actionId": 16
     },
     {
        "actionName":"PATIAL_CALL",
        "actionId": 36
     }
 ]
export const AllActionType = [
    STOCK_SPLIT, BONUS_SHARE, RIGHT,CONSOLIDATION, SHARE_SWITCH, THREASURY_STOCK_CANCEL, BONDS_TO_SHARE,PP_PATNER,EMPLOYEE_STOCK_OPTION,REDUCE_SHARE,
    CASH_DIVIDEND, DISTRIBUTED_TRASURY_STOCK
];

export const currency = currencyjson;
